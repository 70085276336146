/* Вся полоса прокрутки */
::-webkit-scrollbar {
    width: 10px; /* Ширина скроллбара */
  }
  
  /* Ползунок (движущаяся часть) */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Цвет ползунка */
    border-radius: 10px; /* Закругление углов */
  }
  
  /* Фон полосы прокрутки */
  ::-webkit-scrollbar-track {
    background-color: #ffffff; /* Цвет фона полосы */
  }