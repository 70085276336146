@import "../../../../mixins.scss";
.slick-dots{
    display: none!important;
}
.competition{
    @include flex-column;
    position: relative;
    right: 63px;
    @media (max-width: 420px) {
        flex-direction: column;
        right: 5px;
        .competition-head{
            margin-left: 20px!important;
        }
    }
    .competition-head{
        @include flex-center;
        margin-left: 60px;
        @media (max-width: 420px) {
            flex-direction: column;
            
        }
        
        .competition-menu{
            @include flex-center;
            margin-left: 50px;
            background-color: rgba(245, 240, 240, 0.822);
            padding: 10px;
            column-gap: 10px;
            border-radius: 5px;
            li{
                margin: 0 40px;
                list-style: none;
                cursor: pointer;
            }
            @media (max-width: 420px) {
                margin-left: 0;
                li{
                    margin: 0 10px;
                }
                
            }
            
        }
    }
    
    .competition-block{
        .weekly-single{
            &:hover{
                .weekly-caption{// смещаем изображение вниз на 10px при наведении
                    transform: translateY(10px);
                }
            }
            .weekly-img-competition{
                width: 450px; // Размер квадрата
                height: 250px; // Размер квадрата
                overflow: hidden;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                @media (max-width: 420px) {
                    width: 350px;
                    // margin-left: 115px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    margin: 0;
                }
                @media (max-width: 400px) {
                    width: 350px;
                    // margin-left: 115px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .weekly-caption{
            background-color: rgba(99, 98, 98, 0.616)!important;            display: block;
            transition: transform 0.3s ease;
            h4{
                a{
                    text-decoration: none;      
                    border-radius: 5px;
                    padding: 5px;
                    color: white!important;  
                }
            }
        }
        @include grid-template-columns;
        grid-template-columns: repeat(2, 350px);
        row-gap: 40px;
        column-gap: 120px;
        img{
            width: 370px;
            height: fit-content;
            overflow: hidden; // скрывает части изображения, выходящие за рамки контейнера
            position: relative; // необходим для абсолютного позиционирования изображения
        }
        // @media (max-width: 800px) {
        //     flex-direction: column;
        // }
        @media (max-width: 820px) {
            row-gap: 30px;
            column-gap: 15px;
            margin-left: 100px;
        }
        @media (max-width: 420px) {
            @include grid-template-columns;
            grid-template-columns: repeat(1, 300px);
            margin-left: 0;
            .weekly-img{
                width: 300px;
            }
        }
        @media (max-width: 400px) {
            @include grid-template-columns;
            grid-template-columns: repeat(1, 300px);
            .weekly-img{
                width: 300px;
            }
        }
    }
}
