@import '../../../../../mixins.scss';

.about-img{
    @include flex-column; 
    img{
        width: 1000px;
        height: 600px;
        object-fit: cover;
        @media (max-width: 420px) {
            width: 350px;
            height: 350px;
        }
        @media (max-width: 400px) {
            width: 350px;
            height: 350px;
        }
    }
}

.competition-name{
    @include flex-center;
    margin-top: 50px;
    margin-bottom: 50px;
    h3{
        text-align: center;
    }
}

.competition-top-blocks{
    @include flex-center;
    margin-bottom: 50px;
    .competition-contry{
        @include flex-center;
        justify-content: flex-start;
        margin-top: 10px;
        h6{
            margin-left: 15px;
            margin-top: 3px;
        }
        @media (max-width: 420px) {
            flex-direction: column;
        }
    }
    .competition-top-vertical-line{
        width: 1px;
        background-color: black;
        height: 200px;
        margin-left: 50px;
        margin-right: 50px;
    }
    h6{
        text-align: center;
        display: flex;
        align-items: center;
    }

    .competition-top{
        hr{
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    @media (max-width: 420px) {
        flex-direction: column;
        // align-items: flex-start;
        .competition-top-vertical-line{
            display: none;
        }
        .competition-top{
            margin-top: 10px;
            hr{
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
    // @media (max-width: 400px) {
    //     flex-direction: column;
    //     align-items: flex-start;
    //     .competition-top-vertical-line{
    //         display: none;
    //     }
    //     .competition-top{
    //         margin-top: 10px;
    //         hr{
    //             margin-top: 10px;
    //             margin-bottom: 10px;
    //         }
    //     }
    // }
}
.competition-category{
    @include flex-center;
    @media (max-width: 420px) {
        img{
            width: 350px;
            height: 200px;   
        }
    }
    @media (max-width: 400px) {
        img{
            width: 350px;
            height: 200px;   
        }
    }
}
.text-competition-content{
    @include flex-center; 
    margin-top: 50px;
    margin-bottom: 50px;
    @media (max-width: 420px) {
        flex-direction: column;
        align-items: center;
        width: 350px;
        img{
            width: 350px;
        }
    }
    @media (max-width: 400px) {
        flex-direction: column;
        align-items: center;
        width: 350px;
        img{
            width: 350px;
        }
    }
}