@import "./Components/Header/header.scss";
@import "./Pages/Admin/admin.scss";
@import "./Components/Main/MainComponents/ClubList/ClubsList.scss";
@import "./Components/Main/Main.scss";
@import "./Components/Main/MainComponents/YouTube/YouTube.scss";
// @import "./Components/Footer/Footer.scss";

.notranslate{
    display: none;
}

/* src/Components/Main/Main.css */
.main-container { /* Путь к изображению по умолчанию */
    position: relative;
    width: 100%;
    height: auto;
    background-size: cover; /* Оставляем оригинальный размер изображения */
    background-position: center; /* Центрируем изображение */
    background-repeat: no-repeat; /* Запрещаем повторение изображения */
    background-attachment: fixed;
    overflow: hidden;
    padding: 20px;
    z-index: 0;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        linear-gradient(to bottom, white, rgba(255, 255, 255, 0)), /* Градиент сверху вниз */
        linear-gradient(to top, rgb(255, 255, 255), rgba(226, 224, 224, 0));   /* Градиент снизу вверх */
    // background-color: rgb(226 224 224 / 73%); /* Полупрозрачное наложение */
    // background: linear-gradient(to bottom, white, black);
    z-index: -9;
}