@import '../../../../mixins.scss';

.seminar-heading{
    @include flex-center;
    position: relative;
    right: 460px;
    @media screen and (max-width: 820px) {
        right: 265px;
    }
    @media screen and (max-width:420px) {
        right: 0;
    }
}
.carousel-container{
    margin-left: -50px;
}

.slick-dots{
    display: none!important;
}

.seminar-list{
    height: 260px;
    @include flex-center;
    overflow-x: hidden;
    transition: transform 0.5s ease;
    .slick-dots{
        display: none!important;
    }
    
    .slick-slider{
        width: 100%;
        .weekly-img{
            width: 250px; // Размер квадрата
            height: 200px; // Размер квадрата
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            @media (max-width: 800px) {
                width: 270px;
            }
            @media (max-width: 420px) {
                width: 300px;
            }
            @media (max-width: 400px) {
                width: 300px;
            }
        }
        .weekly-caption{
            a{
                text-decoration: none;      
                border-radius: 5px;
                padding: 5px;
                color: black!important;
                
            }
        }
    }
}
