@import "../../../../../mixins.scss";
.seminar{
    @include flex-column;
    position: relative;
    right: 63px;
    .seminar-head{
        @include flex-center;
        margin-left: 60px;
        @media (max-width: 375px) {
            flex-direction: column;
            
        }
        
        .seminar-menu{
            @include flex-center;
            margin-left: 50px;
            background-color: rgba(245, 240, 240, 0.822);
            padding: 10px;
            column-gap: 10px;
            border-radius: 5px;
            li{
                margin: 0 40px;
                list-style: none;
                cursor: pointer;
            }
            @media (max-width: 375px) {
                margin-left: 0;
                li{
                    margin: 0 10px;
                }
                
            }
            
        }
    }
    
    .seminar-block{
        .weekly-single{
            &:hover{
                .weekly-caption{// смещаем изображение вниз на 10px при наведении
                    transform: translateY(10px);
                }
            }
            .weekly-img{
                width: 450px; // Размер квадрата
                height: 250px; // Размер квадрата
                overflow: hidden;
                position: relative;
                @media (max-width: 420px) {
                    width: 350px;
                }
                @media (max-width: 400px) {
                    width: 350px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
        .weekly-caption{
            background-color: rgb(255, 255, 255, 0.8);
            display: block;
            transition: transform 0.3s ease;
        }
        @include grid-template-columns;
        grid-template-columns: repeat(2, 350px);
        row-gap: 40px;
        column-gap: 120px;
        img{
            width: 370px;
            height: fit-content;
            overflow: hidden; // скрывает части изображения, выходящие за рамки контейнера
            position: relative; // необходим для абсолютного позиционирования изображения
        }
        // @media (max-width: 800px) {
        //     flex-direction: column;
        // }
        @media (max-width: 420px) {
            @include grid-template-columns;
            grid-template-columns: repeat(1, 300px);
            .weekly-img{
                width: 300px;
            }
        }
        @media (max-width: 400px) {
            @include grid-template-columns;
            grid-template-columns: repeat(1, 300px);
            .weekly-img{
                width: 300px;
            }
        }
    }
}
.seminar-text{
    width: 770px!important;
    @media (max-width: 375px) {
        width: 350px;
    }
}
