@import '../../../../mixins.scss';

.trending-top{

    width: 755px;
    @include flex-center;
    img{
        border-radius: 5px;
        width: 750px;
        position: relative;
        object-fit: cover;
        height: 450px;
        @media (max-width: 820px) {
            width: 600px;
            height: auto;
            // margin-left: -47px;
        }
        @media (max-width: 420px) {
            width: 350px;
            height: auto;
            // margin-left: -47px;
        }
    }
    @media (max-width: 420px) {
        width: 360px;
        .trend-top-cap{
            a{
                
                h2{
                    margin-right: 12px;
                }
            }
        }
    }
    @media (max-width: 420px) {
        width: auto;
        // margin-left: -25px;
        display: flex;
        justify-content: center;
        // margin-right: -50px;
        .trend-top-cap{
            a{
                
                h2{
                    margin-right: 12px;
                }
            }
        }
    }
    // @media (max-width: 375px) {
    //     width: auto;
    //     // margin-left: -25px;
    //     display: flex;
    //     justify-content: center;
    //     margin-right: -25px;
    //     .trend-top-cap{
    //         a{
                
    //             h2{
    //                 margin-right: 12px;
    //             }
    //         }
    //     }
    // }
    .trend-top-cap{
        a{
            text-decoration: none;
            h2{
                background-color: rgba(99, 98, 98, 0.616);
                border-radius: 5px;
                padding: 5px;
                color: white!important;
            }
        }
    }
}

span{
    // background: #fff9c6;
    color: #000;
    padding: 9px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
    margin-bottom: 19px;
    display: inline-block;
}

.trend-block-bottom{
    @include grid-template-columns;
    grid-template-columns: repeat(3, 233px);
    column-gap: 10px;
    margin-top: 75px;
    grid-row: 10px;
    .trend-bottom-cap{
        a{
            text-decoration: none;      
            border-radius: 5px;
            padding: 5px;
            color: black!important;
            
        }
    }
}
// @media screen and (max-width: 1199px) {
//     .trend-block-bottom{
//         grid-template-columns: repeat(3, 180px);
//     }
// }
@media screen and (max-width: 820px) {
    .trend-block-bottom{
        // grid-template-columns: repeat(2, 180px);
        .row{
            .single-bottom{
                .trend-bottom-img{
                    img{
                        width: 180px;
                        height: 180px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 420px) {
    .trend-block-bottom{
        @include flex-column;
        justify-content: center;
        margin-left: 0!important;
        .single-bottom{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
            .trend-bottom-img{
                img{
                    width: 250px!important;
                    height: 180px;
                }
            }
            .trend-bottom-cap{
                
                h4{
                    
                    a{
                        text-align: center;
                        width: 250px;
                    }
                }
            }
        }
    }
}

.trend-center{
    @include flex-center;
    .trend-center-size{
        width: 800px;
    }
}

.trend-right-blocks{
    margin-bottom: 100px;
    div{
        margin-top: 10px;
    }
    @media screen and (max-width: 820px) {
        margin-left: 60px;
        display: grid;
        grid-template-columns: repeat(2, 350px);
    }
    @media screen and (max-width: 420px) { 
        @include flex-column;
        justify-content: center;
        margin-top: 20px!important;  
        // margin-left: -50px;
        grid-template-columns: repeat(1, 350px);
    }
}

.trand-right-single{
    .trand-right-img{
        margin-right: 10px;
        img{
            width: 150px;
            height: 120px;
            object-fit: cover;
        }
    }
    .trand-right-cap{ 
        a{
            text-decoration: none;      
            border-radius: 5px;
            // padding: 5px;
            color: black!important;
            
        }
        @media screen and (max-width:820px) {
            width: 110px;
        }
        @media screen and (max-width: 420px) {
            width: 150px;
        }
        
    }
}

.trend-bottom-img{
    width: 235px; // Размер квадрата
    height: 200px; // Размер квадрата
    overflow: hidden;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

