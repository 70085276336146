@import '../../mixins.scss';

.align-items-center{
  .vertical-line{
    width: 1px; /* Ширина линии */
    height: 20px; /* Высота линии */
    background-color: black; /* Цвет линии */
    border: none; /* Убираем стандартное оформление hr */
    margin: 0 10px; /* Отступы слева и справа */
  }
}

.container-header{
  @include flex-center;
  // margin-left: 20px;
  //   gap: 40px;
  @media screen and (max-width:420px) {
    align-items: flex-end;
    gap: 20px;
  }
}

#responsive-navbar-nav{
  .computer{

    
    display: flex;
    #federacija{
      .submenu{
        position: absolute;
        z-index: 2;
        display: none;
        background-color: white;
        padding: 10px;
        border: solid rgba(128, 128, 128, 0.164) 1px;
        // li{
        //   display: flex;
        //   align-items: center;
        // }
      }
      &:hover{
        .submenu{
          display: flex;
          margin-left: -80px;
          #liDoc{
            .submenu{
              display: none;
            }
            &:hover{
              .submenu{
                display: flex;
                margin-left: -200px;
              }
            }
          }
          #komis{
            .submenu{
              display: none;
              a{
                color: #002e5b;
              }
            }
            &:hover{
              .submenu{
                display: flex;
              }
            }
          }
        }
        .hidden{
          display: none;
        }
        
      }
    }
}

// .mobile{
//   // display: none;
//   @media screen and (max-width:420px) {
//     position: fixed;
//     top: 0;
//     left: -250px; /* начальная позиция */
//     width: 250px;
//     height: 100%;
//     background-color: #333;
//     color: white;
//     overflow-x: hidden;
//     transition: 0.3s ease; /* плавная анимация */
//     padding-top: 60px;
//     &.open{
//       left: 0; /* меню выезжает */
//     }
//   }
// }
@media screen and (max-width: 820px) {
  .computer{
    display: none;
  }
  .landscape-menu-icon{
    display: block!important;
  }
  .menu-mobile-open-icon-block{
    display: none!important;
  }
}
  @media screen and (max-width: 420px) {
    .landscape-menu-icon{
      display: none!important;
    }
    .computer{
      display: none;
    }
    .menu-mobile-open-icon-block{
      display: block;
    }

    // .mobile{
    //   display: block;
    // }
  }
}

.mobile-menu-icon{
  background-color: white;
  position: fixed;
  top: 50%;
  z-index: 20;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  border-radius: 20px;
  img{
    width: 30px!important;
    color: #a19c9c!important;
  }
  &.open{
    left: 150px;
  }
  @media screen and (max-width: 820px) {
    display: block;
  }
  @media screen and (max-width: 420px) {
    display: block;
  }
}

  .li-header-item{
    // margin-left: 20px;
    // margin-top: 20px;
    padding: 4px;
    list-style: none;
    cursor: pointer;
    a{
      color: #002e5b!important;
      text-decoration: none!important;
    }
    li{
      width: 105px;
    }
  }

  .mobile-menu .navbar-toggler-icon{
    margin-bottom: 0!important;
    padding: 16px 14px;
  }

  .menu-link{
    color: #002e5b;
    ;
  }

  .responsive-navbar-nav .menu-link .nav-link{
    color: #002e5b!important;
      text-decoration: none!important;
    a{
      color: #002e5b!important;
      text-decoration: none!important;
    }
  }

  a{
    color: #002e5b!important;
    text-decoration: none!important;
  }

  .olymp{
    width: auto;
    background: white!important;
    margin: 0!important;
    padding: 0!important;
  }
  .olymp-mobile{
    display: none;
  }

  @media screen and (max-width: 420px) {
    .olymp-mobile{
      display: block!important;
      span{
        background-color: none!important;
      }
    }
    .olymp{
      display: none;
    }
  }

  .eur-wako{
    margin-left: 30px;
    img{
      width: 70px;
      height: 70px;
    }
  }

  .eur-wako-mobile{
    display: none;
    margin: 0;
    img{
      width: 70px;
      height: 70px;
    }
  }

  @media screen and (max-width: 420px) {
    .eur-wako-mobile{
      display: block!important;
    }
    .eur-wako{
      display: none;
    }
  }
  // @media (min-width: 420px) and (max-width: 820px) {
  //   .mobile-menu-toggle {
  //     display: block;
  //   }
  // }
  
  @media (min-width: 821px) {
    .mobile-menu-toggle {
      display: none;
    }
  }

  .landscape {
    align-items: flex-start!important;
    position: absolute;
    display: block;
    z-index: 20;
    background-color: white;
    border-radius: 20px;
    width: fit-content;
    height: fit-content;
    padding: 25px;
    margin-left: -130px;
    transition: 1s;
    // transform: translateX(-100%);
    .submenu {
      display: none; // По умолчанию скрыто
            &.open {
        display: block; // Отображается при добавлении класса open
        // transform: translateX(0%);
        }
    }
    a{
      // padding: 5px;
      margin: 10px 10px;
      @media screen and (max-width: 420px) {
        margin: 0;

      }
    }
    // @media screen and (max-width: 820px) {
    //   .lkflogo-mobile{
    //     display: none!important;
    //   }
    //   display: flex;
    //   padding: 5px;
    //   left: 186px;
    //   top: 90px;
    //   a{
    //     margin: 7px;
    //   }
    //   #federacija{
    //     .submenu{
    //       display: none;
    //       &.open{
    //         display: flex;
    //         margin-left: -106px;
    //       }
    //     }
    //     #liDoc{
    //       .submenu{
    //         display: none;
    //         &.open{
    //           display: flex;
    //           margin-left: -106px;
    //         }
    //       }
    //     }
    //   }
    // }
    @media screen and (max-width:820px) {
      height: 100%;
      margin-left: 0;
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 70px;
      padding-bottom: 160px;
      .lkflogo-mobile{
        display: block!important;
      }
    }
    @media screen and (max-width:420px) {
      width: 200px;
      gap: 50px;
    }
  }

  .landscape-menu-wrapper {
    display: none; /* По умолчанию скрыто */
    &.open {
      display: block; /* Отображается, когда добавлен класс open */
    }
    @media screen and (max-width:820px) {
      position: fixed;
      top: 0;
      left: -250px; /* начальная позиция */
      width: 250px;
      height: 100%;
      color: white;
      overflow-x: hidden;
      z-index: 21;
      // transition: transform 0.3s ease; /* Плавный переход для right */
      // transition: opacity 0.3s ease, visibility 0.3s ease;
      // transform: translateX(-100%);
      // padding-top: 60px;

      &.open{
        left: 0px;
        top: -1px;
        z-index: 21;
        right: 0;
        // transform: translateX(0%);
        // transition: opacity 0.3s ease, visibility 0.3s ease;
        // background: aqua;
        .landscape{
          // transform: translateX(0%)!important;
        }
        .mobile-menu-icon{
          margin-left: 220px;
        }
        @media screen and (max-width:420px) {
          .mobile-menu-icon{
            margin-left: 170px;
          }
        }
      }
    }
  }
  
  .landscape-menu-icon {
    display: none;
    img {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }
  .align-items-center{
    // align-items: flex-start!important;
    // @media screen and (max-width:420px) {
    //   margin-right: 40px;
    // }
    // @media screen and (max-width:420px) {
    //   position: fixed;
    //   top: 0;
    //   left: -250px; /* начальная позиция */
    //   width: 250px;
    //   height: 100%;
    //   // background-color: #333;
    //   color: white;
    //   overflow-x: hidden;
    //   transition: 0.3s ease; /* плавная анимация */
    //   padding-top: 60px;
    //   &.open{
    //     left: 181px; /* меню выезжает */
    //   }
    // }
  }

  .align-top{
    margin: 0 10px;
  }

  .header-block{
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    // margin-left: 140px;
    @media screen and (max-width:820px) {
      // margin-left: 10px;
    }
  }
  .logos-block{
    display: flex;
    @media screen and (max-width:420px) {
      // margin-left: -35px;
      flex-direction: column;
      justify-content: center;
        align-items: center;
    }
  }
  .header-bottom{
    // padding-left: 110px;
    @media screen and (max-width:820px) {
      padding: 0;
    }
  }

  .mobile-menu-background{
    width: 37px;
    // background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0)), linear-gradient(to top, rgb(255, 255, 255), rgba(226, 224, 224, 0));
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%), #000000 50%, rgb(0 0 0 / 0%)), linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0 0 0 / 21%) 50%, rgba(0, 0, 0, 0));
    transition: background 0.3s ease; /* Плавный переход */    
    height: 56%;
    top: 26%;
    left: -7px;
    position: fixed;
    z-index: 19 !important;
    border-radius: 10px;
  }

  .more-menus{
    @media screen and (max-width:820px) {
      right: 85px;
    }
    @media screen and (max-width:420px) {
      right: 43px;
    }
    position: absolute;
    z-index: 21;
    right: 43px;
    background-color: rgba(255, 255, 255, 0.911);
    .more-more-menus{
      position: absolute;
      background-color: white;
      z-index: 1;
      padding-left: 9px !important;
    }
  }

  .menu-mobile-open-icon-block{
    display: none;
    @media screen and (max-width:420px) {
      display:block;
    }
  }
