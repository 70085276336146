.modal-log{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .value-log{
        position: relative;
        z-index: 100;
        width: 350px;
        height: 150px;
        background-color: rgb(199, 195, 195);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        input{
            width: 200px;
            height: 40px;
            border-radius: 5px;
            margin: 3px;
            margin-left: 15px;
        }
        .buttons{
            margin-left: 13px;
            button{
                width: 80px;
                margin: 0 5px;
                border-radius: 5px;
            }
        }
    }
}