@import '../../../mixins.scss';

.background-lite-black {
    background-color: #211e1e;
}
.background-white {
    background-color: white;
}

.display-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.biedri-cards {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(5, 200px);
    column-gap: 10px;
    row-gap: 10px;
    padding: 5px;

    .flip-container {
        perspective: 1000px;
        .flipper {
            position: relative;
            // width: 100%;
            // height: 100%;
            transform-style: preserve-3d;
            transition: transform 0.6s;
            
            .front, .back {
                backface-visibility: hidden;
                position: relative;
                // top: 0;
                // left: 0;
                // width: 100%;
                // height: 100%;
            }
            .front {
                z-index: 2;
                transform: rotateY(0deg);
                img{
                    width: 192px;
                    height: 192px;
                    object-fit: cover;
                }
            }
            .back {
                transform: rotateY(180deg);
                background: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                box-sizing: border-box;
                margin-top: -190px;
            }
        }
        
        &:hover .flipper {
            transform: rotateY(180deg);
        }
    }

    img {
        width: 200px;
    }
    @media (max-width: 820px) {
        grid-template-columns: repeat(3, 200px);
    }
    @media (max-width: 420px) {
        grid-template-columns: repeat(1, 200px);
    }
}

.shield {
    position: relative;
    object-fit: cover;
    width: 200px;
    height: 200px;
    border: 4px solid #7c7c7c;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-club{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}