@import '../../../mixins.scss';

.contryes-style{
    label{
        width: 230px;
        margin: 0 15px;
    }
    select{
        width: 230px;
        height: 40px;
        border: solid 2px rgb(99, 98, 98);
        border-radius: 5px;
        margin: 10px;
    }
}
.club-map-conteiner{
    width: 1200px;
    height: 500px;
    @media (max-width: 375px) {
        width: 350px;
        height: 300px;
    }
}
.ClubMap{
    margin-top: 30px;
    margin-bottom: 50px;
    @include flex-center;
}