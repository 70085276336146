
table {
    border-collapse: collapse;
    width: fit-content;
    margin: 0 auto;
}
th, td {
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
}
th {
    background-color: #f2f2f2;
}
td {
    cursor: pointer;
}
.selected {
    background-color: #007bff;
    color: #fff;
}

.kalendar{
    display: flex;
    justify-content: center;
}

@media(max-width: 400px){
    table{
        width: 378px!important;
    }
    th{
        width: 378px;
    }
}

