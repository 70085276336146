@import '../../../../mixins.scss';

.events-for-year{
@include grid-template-columns;
grid-template-columns: repeat(2, 550px);
justify-content: center;
justify-items: center;
    @media screen and (max-width: 820px) {
        grid-template-columns: repeat(2, 400px);
        .last-competition{
            .card{
                .img-block{
                    img{
                        width: 350px;
                    }
                }
                .header-block{
                    width: 350px;
                }
            }
        }
    }
    @media (max-width: 420px) {
        width: 350px;
        grid-template-columns: repeat(1, 350px);
        height: auto;
    }
    @media (max-width: 400px) {
        width: 350px;
        grid-template-columns: repeat(1, 350px);
        height: auto;
    }
}

.last-competition{
    .card{
        border: none;
        background-color: #ffffff00;
        .img-block{
            img{
                border-radius: 5px;
                width: 400px;
                position: relative;
                object-fit: cover;
                height: 200px;
                @media (max-width: 420px) {
                    width: 350px;
                    height: auto;
                    margin-left: 30px;
                }
                @media (max-width: 400px) {
                    width: 350px;
                    height: auto;
                    margin-left: 30px;
                }
            }
        }
        .date-block{
            .date{

            }
        }
        .header-block{
            margin-bottom: 10px;
            h4{
                a{
                    text-decoration: none;
                    width: 400px;
                    border-radius: 5px;
                    padding: 5px;
                    color: black!important;
                    &:hover{
                        color: rgb(93, 146, 146)
                    }
                    @media (max-width: 420px) {
                        width: 350px;
                        height: auto;
                        margin-left: 30px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                    }
                    @media (max-width: 400px) {
                        width: 350px;
                        height: auto;
                        margin-left: 30px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.show-more-container{
    @include flex-center;
    margin-top: 20px;
    margin-bottom: 20px;
    button{
        border-radius: 9px;
        width: 150px;
        height: 50px;
        font-size: 18px;
        font-variant: all-petite-caps;  
    }
}