@import '../../../../mixins.scss';
@import './ClubsListComponents/Clubs.scss';

.carousel-container{
    margin-right: -50px;
}
.slick-slide{
    display: flex!important;
    justify-content: center!important;
    align-items: center;
    // margin-left: 50px!important;
}

.clubs-heading{
    @include flex-center;
    margin-left: -750px;
    cursor: pointer;
    h3{
        padding-bottom: 30px;
        a{
            text-decoration: none;
            color: black;
        }
    }
    &:hover{
        h3{
            a{
                color: red;
            }
        }
    }
    @media (max-width: 820px) {
        margin-left: -500px;
    }
    @media screen and (max-width:420px) {
        margin: 0;
    }
}

.clubs-list{
    position: relative;
    z-index: 5;
    height: 410px;
    margin-left: -100px;
    overflow-x: hidden;
    transition: transform 0.5s ease;
    .slick-slider{
        width: 100%;
        .weekly-img{
            // width: 350px;
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            @media (max-width: 820px) {
                width: 270px;
                height: 270px;
            }
            @media (max-width: 420px) {
                width: 200px;
                height: 200px;
            }
            @media (max-width: 400px) {
                width: 200px;
                height: 200px;
            }
        }
    }
    @media (max-width: 420px) {
        height: 232px;
    }
    @media (max-width: 400px) {
        height: 232px;
    }
}

.club-elem{
    height: auto;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 20px;
    transition: transform 0.5s ease;
    transform: translateX(0);
    @media (max-width: 800px) {
    }
    @media (max-width: 400px) {
    }
}

.club {
    display: inline-block; /* Отображаем элементы в одной линии */
    margin-right: 20px; /* Отступ между элементами */
    transition: transform 0.5s ease; /* Добавляем плавный переход */
}

.weekly-caption{
    h4{
        a{
            text-decoration: none;      
            border-radius: 5px;
            padding: 5px;
            color: black!important;
            
        }
    }
}



