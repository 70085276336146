@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');

.container{
  max-width: auto!important;
}

.header-area {
  background-color: #333;
  color: white; }

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1000; }

.phone-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.logo {
  margin-left: 20px; }

.menu-link {
  margin-left: 30px;
  color: #002e5b;
  font-weight: 500;
  font-size: 16px; }

.mobile-menu .navbar-toggler-icon {
  margin-bottom: 0 !important;
  padding: 16px 14px; }

span {
  /* background: #fff9c6; */
  color: #000;
  padding: 9px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 1;
  margin-bottom: 19px;
  display: inline-block; }

.trend-block-bottom {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-row: 10px; }

@media screen and (max-width: 1199px) {
  .trend-block-bottom {
    grid-template-columns: repeat(3, 180px);
  } 
}
@media screen and (max-width: 820px) {
  .trend-block-bottom {
    grid-template-columns: repeat(3, 150px);
    gap: 60px; 
    margin-left: 55px;
  } }
@media screen and (max-width: 420px) {
  .trend-block-bottom {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 300px);
    justify-content: center; 
    /* margin-left: -65px; */
    gap: 10px; 
  } 
}
.trend-center {
  display: flex;
  justify-content: center;
  align-items: center; }

/* .trend-right-blocks {
  margin-top: -60px; 
} */


