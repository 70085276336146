.top-img{
    width: 85%;
    height: auto;
    position: relative;
    z-index: 0;
}

.trend-top-cap{
    position: relative;
    margin-top: -150px;
    /* margin-left: 20px */
}

.bottom-img{
    border-radius: 5px;
    width: 90%;
    height: auto;
}

.bottom-post-name-text{
    font-size: 18px;
}
